import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import Home from './Home/Home';
import Services from './Services/Services';
import JobsPage from './Jobs/JobsPage';
import Contact from './Contact/Contact';
import JobApplicationForm from './JobApplicationForm/JobApplicationForm';
import MasterClass from './MasterClass/MasterClass';
import AboutUs from './AboutUs/AboutUs';
import Admin from './Admin/Admin';
import ScrollToTop from './ScrollToTop/ScrollToTop';

const AppContent = () => {
    const [webinarDate, setWebinarDate] = useState('16 Oct 2024');
    const [webinarTime, setWebinarTime] = useState('09:00 AM');
    const [jobs, setJobs] = useState([]);

    const location = useLocation(); // useLocation inside Router context

    const handleUpdateWebinarDateTime = (newDate, newTime) => {
        setWebinarDate(newDate);
        setWebinarTime(newTime);
    };

    const handlePostJob = (newJob) => {
        setJobs((prevJobs) => [...prevJobs, newJob]);
    };

    useEffect(() => {
        document.title = "Slope Rate Techno Private Limited";
    }, []);

    return (
        <div>
            <Navbar />
            {/* Conditionally render title only on the home page */}
            {location.pathname === "/" && <h1>Slope Rate Techno Private Limited</h1>}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/services" element={<Services />} />
                <Route path="/jobs" element={<JobsPage jobs={jobs} />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/apply" element={<JobApplicationForm />} />
                <Route 
                    path="/masterclass" 
                    element={<MasterClass webinarDate={webinarDate} webinarTime={webinarTime} />} 
                />
                <Route 
                    path="/admin" 
                    element={<Admin 
                        onPostJob={handlePostJob} 
                        onUpdateWebinarDateTime={handleUpdateWebinarDateTime} 
                    />} 
                />
            </Routes>
            <Footer />
        </div>
    );
};

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <AppContent />
        </Router>
    );
};

export default App;
