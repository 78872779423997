import React, { useState } from 'react';
import './Contact.css'; // Import the CSS file
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Import icons

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };

  return (
    <div className="container">
      <h1 className="main-heading">Contact Us</h1>

      <div className="row">
        {/* Address Box */}
        <div className="column info-box">
          <div className="icon-container">
            <FaMapMarkerAlt className="icon" />
          </div>
          <h2 className="heading">Address</h2>
          <p className="address">
            Behind Suryaa News Paper HO, Ayyappa Society, Siddhi Vinayak Nagar, Madhapur, Hyderabad, Telangana 500081, India
          </p>
          <p>
            <strong>GST:</strong> 37ABECS7676L2ZH<br />
            <strong>HR:</strong> +91 8500 298 398
          </p>
        </div>
        
        {/* Phone Box */}
        <div className="column info-box">
          <div className="icon-container">
            <FaPhoneAlt className="icon" />
          </div>
          <h2 className="heading">Phone</h2>
          <p>
            <strong>India:</strong> +91 6281248144<br />
          </p>
        </div>
        
        {/* Email Box */}
        <div className="column info-box">
          <div className="icon-container">
            <FaEnvelope className="icon" />
          </div>
          <h2 className="heading">Email</h2>
          <p>
            <strong>Email Us:</strong> Admin@sloperateinc.com
          </p>
        </div>
      </div>

      <div className="map-container">
      <iframe
    title="Google Maps"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.069969049788!2d78.38765157387385!3d17.456364883442603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91f3d51e64c5%3A0xc8fa92699ce45ae6!2sSlope%20Rate%20Techno%20Private%20Limited!5e0!3m2!1sen!2sin!4v1729660932512!5m2!1sen!2sin"
    width="100%"
    height="300"
    style={{ border: 0 }}
    allowFullScreen
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
/>


      </div>

      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label>
              <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" required />
            </label>
          </div>
          <div className="form-group">
            <label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email" required />
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>
            <input type="text" name="subject" value={formData.subject} onChange={handleChange} placeholder="Subject" required />
          </label>
        </div>
        <div className="form-group">
          <label>
            <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Your Message" required></textarea>
          </label>
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default Contact;
