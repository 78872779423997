import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Jobs.css';

const JobsPage = () => {
    const navigate = useNavigate();
    const [jobs, setJobs] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [filter, setFilter] = useState('All');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                setLoading(true);
                const response = await axios.get('https://sloperatebackend-a8da1f1de4d3.herokuapp.com/api/jobs');
                
                // Log the full response to understand the structure
                console.log("API response:", response);

                // Check if response data contains an array directly or an object with jobs array
                if (Array.isArray(response.data)) {
                    setJobs(response.data);
                } else if (response.data && Array.isArray(response.data.jobs)) {
                    setJobs(response.data.jobs);
                } else {
                    console.error("Unexpected data format:", response.data);
                    setError('Unexpected data format. Please try again later.');
                }
            } catch (err) {
                console.error("Error fetching jobs:", err);
                setError('Failed to fetch jobs. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
        fetchJobs();
    }, []);

    // Filter jobs based on the selected filter
    const filteredJobs = filter === 'All' ? jobs : jobs.filter(job => job.company === filter);

    const openDetails = (job) => {
        setSelectedJob(job);
        setShowDetails(true);
    };

    const closeDetails = () => {
        setShowDetails(false);
        setSelectedJob(null);
    };

    const handleApply = () => {
        navigate('/apply');
    };

   
    return (
        <div className="container py-5">
            <h1 className="display-4 text-center mb-5">Open Positions</h1>
            
            <div className="row mb-4">
                <div className="col-12">
                    <div className="d-flex justify-content-center gap-2">
                        <button className="btn btn-primary px-4" onClick={() => setFilter('All')}>
                            All Positions
                        </button>
                    </div>
                </div>
            </div>

            {loading && (
                <div className="text-center py-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}

            {!loading && !error && (
                <div className="row g-4">
                    {filteredJobs.length > 0 ? (
                        filteredJobs.map((job, index) => (
                            <div key={index} className="col-12 col-md-6 col-lg-4">
                                <div className="card h-100 shadow-sm hover-shadow">
                                    <div className="card-body">
                                        <h5 className="card-title text-primary mb-3">{job.title}</h5>
                                        <div className="card-text">
                                            <p className="mb-2 text-muted">
                                                <i className="bi bi-geo-alt me-2"></i>
                                                {job.location}
                                            </p>
                                            <p className="mb-2">
                                                <strong>Required Skills:</strong><br/>
                                                <span className="text-truncate d-inline-block w-100">
                                                    {Array.isArray(job.skills) ? job.skills.join(', ') : (job.skills || 'N/A')}
                                                </span>
                                            </p>
                                        </div>
                                        <button 
                                            className="btn btn-outline-primary w-100 mt-3" 
                                            onClick={() => openDetails(job)}
                                        >
                                            View Details
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col-12 text-center py-5">
                            <h3 className="text-muted">No Jobs in this Category</h3>
                        </div>
                    )}
                </div>
            )}

            {showDetails && selectedJob && (
                <div className="modal fade show d-block" style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header bg-light">
                                <h5 className="modal-title">{selectedJob.title}</h5>
                                <button type="button" className="btn-close" onClick={closeDetails}></button>
                            </div>
                            <div className="modal-body">
                                <div className="p-3">
                                    <div className="mb-4">
                                        <h6 className="text-primary mb-3">Job Overview</h6>
                                        <p className="mb-2"><i className="bi bi-geo-alt me-2"></i>{selectedJob.location}</p>
                                        <p className="mb-2"><i className="bi bi-briefcase me-2"></i>{selectedJob.experience}</p>
                                        <p className="mb-2"><i className="bi bi-mortarboard me-2"></i>{selectedJob.education}</p>
                                    </div>
                                    
                                    <div className="mb-4">
                                        <h6 className="text-primary mb-3">Description</h6>
                                        <p>{selectedJob.description}</p>
                                    </div>

                                    <div className="mb-4">
                                        <h6 className="text-primary mb-3">Required Skills</h6>
                                        <p>{Array.isArray(selectedJob.skills) ? selectedJob.skills.join(', ') : (selectedJob.skills || 'N/A')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeDetails}>Close</button>
                                <button type="button" className="btn btn-primary px-4" onClick={handleApply}>Apply Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default JobsPage;