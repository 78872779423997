import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Form, Button, Alert, Nav, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import JobPostingForm from './JobPostingForm';
import {
    Briefcase, Calendar, Clock, Eye, Lock,
    LogIn, Plus, Trash2, User, Video
} from 'lucide-react';

const Admin = ({ onPostJob, onUpdateWebinarDateTime }) => {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [webinarStartDate, setWebinarStartDate] = useState('');
    const [webinarStartTime, setWebinarStartTime] = useState('');
    const [timePeriod, setTimePeriod] = useState('AM');
    const [webinarSchedule1Date, setWebinarSchedule1Date] = useState('');
    const [webinarSchedule2Date, setWebinarSchedule2Date] = useState('');
    const [webinarSchedule1Time, setWebinarSchedule1Time] = useState('');
    const [webinarSchedule2Time, setWebinarSchedule2Time] = useState('');
    const [activeTab, setActiveTab] = useState('jobs');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (credentials.username === 'admin' && credentials.password === 'admin') {
            
            setIsLoggedIn(true);
            fetchJobs();
        } else {
            setError('Invalid username or password.');
        }
    };

    const handlePostJob = async (newJob) => {
        try {
            const response = await axios.post('https://sloperatebackend-a8da1f1de4d3.herokuapp.com/api/jobs', newJob);
            if (response.status === 200 || response.status === 201) {
                const createdJob = response.data;
                setJobs(prevJobs => [...prevJobs, createdJob]);
                onPostJob(createdJob);
                setMessage('Job posted successfully.');
                setError('');
                await fetchJobs();
                setActiveTab('viewJobs');
            }
        } catch (error) {
            console.error('Error posting job:', error);
            setError(error.response ? `Failed to post job: ${error.response.data.message || error.response.statusText}` : 'Failed to post job. Please try again.');
        }
    };

    const fetchJobs = async () => {
        try {
            const response = await axios.get('https://sloperatebackend-a8da1f1de4d3.herokuapp.com/api/jobs');
            setJobs(response.data || []);
            setError('');
        } catch (error) {
            console.error('Error fetching jobs:', error);
            setError(error.response ? `Failed to fetch jobs: ${error.response.data.message || error.response.statusText}` : 'Failed to fetch jobs. Please try again.');
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchJobs();
        }
    }, [isLoggedIn]);

    const handleDeleteJob = async (jobId) => {
        try {
            await axios.delete(`https://sloperatebackend-a8da1f1de4d3.herokuapp.com/api/jobs/${jobId}`);
            setJobs((prevJobs) => prevJobs.filter(job => job.id !== jobId));
            setMessage(`Job with ID ${jobId} deleted successfully.`);
            setError('');
        } catch (error) {
            console.error('Error deleting job:', error);
            setError(error.response ? `Failed to delete job: ${error.response.data.message || error.response.statusText}` : 'Failed to delete job. Please try again.');
        }
    };

    const handleWebinarUpdate = async (e) => {
        e.preventDefault();
        const formattedStartTime = `${webinarStartTime} ${timePeriod}`;

        const updatedWebinar = {
            date: webinarStartDate,
            time: formattedStartTime,
            webinarschedule1startdate: webinarSchedule1Date,
            webinarschedule1starttime: `${webinarSchedule1Time} ${timePeriod}`,
            webinarschedule2startdate: webinarSchedule2Date,
            webinarschedule2starttime: `${webinarSchedule2Time} ${timePeriod}`,
        };

        try {
            const response = await axios.post(`https://sloperatebackend-a8da1f1de4d3.herokuapp.com/api/webinars`, updatedWebinar);
            if (response.status === 200) {
                if (onUpdateWebinarDateTime) {
                    onUpdateWebinarDateTime(webinarStartDate, formattedStartTime);
                }
                setMessage('Webinar details updated successfully!');
                setError('');
            }
        } catch (error) {
            console.error('Error updating webinar:', error);
            setError(error.response ? `Server error: ${error.response.data.message || error.response.statusText}` : 'An unexpected error occurred. Please try again later.');
        }
    };

    return (
        <Container fluid className="p-0">
            {!isLoggedIn ? (
                <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center bg-light px-3">
                    <Col xs={12} sm={10} md={6} lg={4}>
                        <Card className="shadow-lg border-0 rounded-lg my-3">
                            <Card.Body className="px-4 py-5">
                                <div className="text-center mb-4">
                                    <div className="bg-primary rounded-circle p-3 d-inline-flex mb-3">
                                        <Lock className="text-white" size={30} />
                                    </div>
                                    <h2 className="fw-bold">Admin Login</h2>
                                </div>

                                {error && <Alert variant="danger">{error}</Alert>}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="d-flex align-items-center">
                                            <User size={18} className="me-2" />
                                            Username
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            value={credentials.username}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-4">
                                        <Form.Label className="d-flex align-items-center">
                                            <Lock size={18} className="me-2" />
                                            Password
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={credentials.password}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>

                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="w-100 d-flex align-items-center justify-content-center"
                                    >
                                        <LogIn size={20} className="me-2" />
                                        Login
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Container>
            ) : (
                <Container fluid className="bg-light min-vh-100 py-3 px-2">
                    <Container className="px-0">
                        <Card className="border-0 shadow-sm">
                            <Card.Body className="p-3 p-md-4">
                                <h2 className="mb-4 fw-bold">Admin Dashboard</h2>

                                {(message || error) && (
                                    <Alert variant={message ? "success" : "danger"}>
                                        {message || error}
                                    </Alert>
                                )}

                                <Nav variant="pills" className="mb-4 gap-2 flex-column flex-md-row">
                                    <Nav.Item>
                                        <Nav.Link
                                            active={activeTab === 'jobs'}
                                            onClick={() => setActiveTab('jobs')}
                                            className="d-flex align-items-center"
                                        >
                                            <Plus size={18} className="me-2" />
                                            Post Jobs
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            active={activeTab === 'viewJobs'}
                                            onClick={() => { setActiveTab('viewJobs'); fetchJobs(); }}
                                            className="d-flex align-items-center"
                                        >
                                            <Eye size={18} className="me-2" />
                                            View Jobs
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            active={activeTab === 'webinar'}
                                            onClick={() => setActiveTab('webinar')}
                                            className="d-flex align-items-center"
                                        >
                                            <Video size={18} className="me-2" />
                                            Update Webinar
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <div className="bg-white rounded">
                                    {activeTab === 'jobs' && (
                                        <div className="p-4">
                                            <JobPostingForm onPostJob={handlePostJob} />
                                        </div>
                                    )}

                                    {activeTab === 'viewJobs' && (
                                        <div className="p-4">
                                            <h4 className="mb-4 d-flex align-items-center">
                                                <Briefcase size={20} className="me-2" />
                                                Posted Jobs
                                            </h4>
                                            <Row xs={1} md={2} lg={3} className="g-3">
                                                {jobs.length > 0 ? (
                                                    jobs.map((job, index) => (
                                                        <Col key={index}>
                                                            <Card className="h-100 shadow-sm">
                                                                <Card.Body>
                                                                    <div className="d-flex justify-content-between align-items-start mb-3">
                                                                        <Card.Title className="mb-0">{job.title}</Card.Title>
                                                                        <Button
                                                                            variant="link"
                                                                            className="text-danger p-0 bg-transparent border-0"
                                                                            onClick={() => handleDeleteJob(job.id)}
                                                                        >
                                                                            <Trash2 size={18} />
                                                                        </Button>
                                                                    </div>
                                                                    <Card.Text className="small text-muted mb-2">ID: {job.id}</Card.Text>
                                                                    <Card.Text className="small text-muted mb-2">Location: {job.location}</Card.Text>
                                                                    <Card.Text className="small text-muted mb-2">Skills: {job.skills}</Card.Text>
                                                                    <Card.Text className="small">{job.description}</Card.Text>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                ) : (
                                                    <Col>
                                                        <p className="text-muted fst-italic">No jobs found.</p>
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    )}

                                    {activeTab === 'webinar' && (
                                        <Form onSubmit={handleWebinarUpdate} className="p-4">
                                            <div className="mb-4">
                                                <h4 className="d-flex align-items-center border-bottom pb-3">
                                                    <Video size={24} className="me-2 text-primary" />
                                                    Webinar Schedule Management
                                                </h4>
                                                <p className="text-muted">Configure upcoming webinar sessions and Demo schedules</p>
                                            </div>

                                            <div className="row g-4">
                                                <div className="col-12 col-lg-6">
                                                    <Card className="h-100 border-0 shadow-sm">
                                                        <Card.Header className="bg-primary bg-opacity-10 border-0">
                                                            <h5 className="mb-0 d-flex align-items-center">
                                                                <Calendar size={20} className="me-2" />
                                                                Main Webinar Session
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <Row className="g-3">
                                                                <Col xs={12}>
                                                                    <Form.Group>
                                                                        <Form.Label className="fw-semibold">Session Date</Form.Label>
                                                                        <Form.Control
                                                                            type="date"
                                                                            value={webinarStartDate}
                                                                            onChange={(e) => setWebinarStartDate(e.target.value)}
                                                                            min={getTodayDate()}
                                                                            className="border-0 shadow-sm"
                                                                            required
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <Form.Group>
                                                                        <Form.Label className="fw-semibold">Session Time</Form.Label>
                                                                        <div className="d-flex gap-2">
                                                                            <Form.Control
                                                                                type="time"
                                                                                value={webinarStartTime}
                                                                                onChange={(e) => setWebinarStartTime(e.target.value)}
                                                                                className="border-0 shadow-sm flex-grow-1"
                                                                                required
                                                                            />
                                                                            <Form.Select
                                                                                value={timePeriod}
                                                                                onChange={(e) => setTimePeriod(e.target.value)}
                                                                                className="border-0 shadow-sm w-auto"
                                                                            >
                                                                                <option value="AM">AM</option>
                                                                                <option value="PM">PM</option>
                                                                            </Form.Select>
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>

                                                <div className="col-12 col-lg-6">
                                                    <Card className="h-100 border-0 shadow-sm">
                                                        <Card.Header className="bg-primary bg-opacity-10 border-0">
                                                            <h5 className="mb-0 d-flex align-items-center">
                                                                <Clock size={20} className="me-2" />
                                                                Demo Sessions
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <div className="mb-4">
                                                                <h6 className="fw-semibold mb-3">Demo session 1</h6>
                                                                <Row className="g-3">
                                                                    <Col xs={12} md={6}>
                                                                        <Form.Group>
                                                                            <Form.Label>Date</Form.Label>
                                                                            <Form.Control
                                                                                type="date"
                                                                                value={webinarSchedule1Date}
                                                                                onChange={(e) => setWebinarSchedule1Date(e.target.value)}
                                                                                min={getTodayDate()}
                                                                                className="border-0 shadow-sm"
                                                                                required
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={12} md={6}>
                                                                        <Form.Group>
                                                                            <Form.Label>Time</Form.Label>
                                                                            <div className="d-flex gap-2">
                                                                                <Form.Control
                                                                                    type="time"
                                                                                    value={webinarSchedule1Time}
                                                                                    onChange={(e) => setWebinarSchedule1Time(e.target.value)}
                                                                                    className="border-0 shadow-sm flex-grow-1"
                                                                                    required
                                                                                />
                                                                                <Form.Select
                                                                                    value={timePeriod}
                                                                                    onChange={(e) => setTimePeriod(e.target.value)}
                                                                                    className="border-0 shadow-sm w-auto"
                                                                                >
                                                                                    <option value="AM">AM</option>
                                                                                    <option value="PM">PM</option>
                                                                                </Form.Select>
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            <div>
                                                                <h6 className="fw-semibold mb-3">Demo Session 2</h6>
                                                                <Row className="g-3">
                                                                    <Col xs={12} md={6}>
                                                                        <Form.Group>
                                                                            <Form.Label>Date</Form.Label>
                                                                            <Form.Control
                                                                                type="date"
                                                                                value={webinarSchedule2Date}
                                                                                onChange={(e) => setWebinarSchedule2Date(e.target.value)}
                                                                                min={getTodayDate()}
                                                                                className="border-0 shadow-sm"
                                                                                required
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={12} md={6}>
                                                                        <Form.Group>
                                                                            <Form.Label>Time</Form.Label>
                                                                            <div className="d-flex gap-2">
                                                                                <Form.Control
                                                                                    type="time"
                                                                                    value={webinarSchedule2Time}
                                                                                    onChange={(e) => setWebinarSchedule2Time(e.target.value)}
                                                                                    className="border-0 shadow-sm flex-grow-1"
                                                                                    required
                                                                                />
                                                                                <Form.Select
                                                                                    value={timePeriod}
                                                                                    onChange={(e) => setTimePeriod(e.target.value)}
                                                                                    className="border-0 shadow-sm w-auto"
                                                                                >
                                                                                    <option value="AM">AM</option>
                                                                                    <option value="PM">PM</option>
                                                                                </Form.Select>
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </div>

                                            <div className="mt-4">
                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                    className="d-flex align-items-center justify-content-center gap-2 w-100 py-2"
                                                    size="lg"
                                                >
                                                    <Calendar size={20} />
                                                    Update Webinar Schedule
                                                </Button>
                                            </div>
                                        </Form>
                                    )}


                                </div>
                            </Card.Body>
                        </Card>
                    </Container>
                </Container>
            )}
        </Container>
    );
};

export default Admin;

