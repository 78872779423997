import React, { useState, useEffect } from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/images/logo1.jpeg';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
            if (window.innerWidth > 1024) {
                setIsMenuOpen(false);
            }
        };

        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
            <div className="contact-info">
                <span className="email">📧 Admin@sloperateinc.com</span>
                <div className="vertical-line"></div>
                <span className="phone">📞 INDIA: +91 6281248144</span>
                <div className="social-media">
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                        <FaFacebookF className="social-icon" />
                    </a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <FaTwitter className="social-icon" />
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                        <FaLinkedinIn className="social-icon" />
                    </a>
                </div>
            </div>
            <div className="horizontal-border"></div>

            <div className="navbar-content">
                <div id="logo">
                    <Link to="/">
                        <img src={logo} alt="SLOPERATE TECHNO" title="SLOPERATE TECHNO" />
                    </Link>
                </div>
                
                <div className="menu-toggle" onClick={toggleMenu}>
                    {isMenuOpen ? <FaTimes /> : <FaBars />}
                </div>

                <nav className={`nav-links ${isMobile ? 'mobile' : ''}`}>
                    <ul className="right-section">
                        <li><Link to="/masterclass">MASTERCLASS</Link></li>
                        <li><Link to="/admin">ADMIN</Link></li>
                        <li><Link to="/">HOME</Link></li>
                        <li><Link to="/aboutus">ABOUT US</Link></li>
                        <li><Link to="/services">SERVICES</Link></li>
                        <li><Link to="/jobs">JOBS</Link></li>
                        <li><Link to="/contact">CONTACT</Link></li>
                    </ul>
                </nav>
            </div>

            {isMobile && (
                <>
                    <div className={`overlay ${isMenuOpen ? 'show' : ''}`} onClick={closeMenu}></div>
                    <div className={`popup-menu ${isMenuOpen ? 'open' : ''}`}>
                        <ul className="popup-menu-items">
                            <li><Link to="/masterclass" onClick={closeMenu}>MASTERCLASS</Link></li>
                            <li><Link to="/admin" onClick={closeMenu}>ADMIN</Link></li>
                            <li><Link to="/" onClick={closeMenu}>HOME</Link></li>
                            <li><Link to="/aboutus" onClick={closeMenu}>ABOUT US</Link></li>
                            <li><Link to="/services" onClick={closeMenu}>SERVICES</Link></li>
                            <li><Link to="/jobs" onClick={closeMenu}>JOBS</Link></li>
                            <li><Link to="/contact" onClick={closeMenu}>CONTACT</Link></li>
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};

export default Navbar;
