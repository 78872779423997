import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-section">
                <h2>Address</h2>
                <address>
                    Behind Suryaa News Paper HO,<br />
                    Ayyappa Society,<br />
                    Siddhi Vinayak Nagar,<br />
                    Madhapur,<br />
                    Hyderabad,<br />
                    Telangana 500081,<br />
                    India
                </address>
                <p>
                    GST: 37ABECS7676L2ZH<br />
                    HR: +91 8500 298 398<br />
                    Jobs: <a href="mailto:jobs@sloperateinc.com">jobs@sloperateinc.com</a>
                </p>
                <div className="social-media1">
                    <a href="#" aria-label="Facebook"><FaFacebookF className="social-icon1" /></a>
                    <a href="#" aria-label="Twitter"><FaTwitter className="social-icon1" /></a>
                    <a href="#" aria-label="LinkedIn"><FaLinkedinIn className="social-icon1" /></a>
                </div>
            </div>

            <div className="footer-section">
                <h2>Useful Links</h2>
                <ul className="useful-links">
                    <li><a href="/">Home</a></li>
                    <li><a href="/aboutus">About Us</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                </ul>
            </div>

            <div className="footer-section">
                <h2>Our Services</h2>
                <ul>
                    <li>Web Development</li>
                    <li>
                        Mobile Development 
                        <span className="small-text" style={{ marginLeft: '5px' }}>(Android/iOS)</span>
                    </li>
                    <li>Analytics Design</li>
                    <li>Artificial Innovation</li>
                    <li>Business Design</li>
                </ul>
            </div>

            <div className="footer-section">
                <h2>Contact Us</h2>
                <p style={{ marginBottom: '5px', fontFamily: 'Roboto, sans-serif' }}>
                    Sales:<br />
                    India: +91 6281248144
                </p>
                <p style={{ fontFamily: 'Roboto, sans-serif' }}>
                    Email Us: <a href="mailto:Admin@sloperateinc.com">Admin@sloperateinc.com</a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
