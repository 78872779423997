import React, { useState } from 'react';
import axios from 'axios';
import './JobPostingForm.css'

const JobPostingForm = ({ setPostedJobs }) => {
    const [jobDetails, setJobDetails] = useState({
        title: '',
        id: '',
        location: '',
        skills: '',
        description: '',
        experience: '',
        education: '',
    });
    const [message, setMessage] = useState(''); // To display success or error messages

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setJobDetails({ ...jobDetails, [name]: value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://sloperatebackend-a8da1f1de4d3.herokuapp.com/api/jobs', jobDetails);

            // Display a success message
            setMessage(`Job with ID ${jobDetails.id} has been posted successfully.`);

            // Update the posted jobs list if the setPostedJobs function is provided
            if (setPostedJobs) {
                setPostedJobs(prevJobs => [...prevJobs, jobDetails]);
            }

            // Reset the form after successful job posting
            setJobDetails({
                title: '',
                id: '',
                location: '',
                skills: '',
                description: '',
                experience: '',
                education: '',
            });
        } catch (error) {
            console.error('Error posting job:', error);
            if (error.response) {
                setMessage(`Error: ${error.response.data.message || 'Unable to post the job.'}`);
            } else if (error.request) {
                setMessage('Error: No response from the server. Please try again.');
            } else {
                setMessage('Error: An unexpected error occurred.');
            }
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="job-posting-form">
                <input 
                    type="text" 
                    name="title" 
                    value={jobDetails.title} 
                    onChange={handleChange} 
                    placeholder="Job Title" 
                    required 
                />
                <input 
                    type="number" 
                    name="id" 
                    value={jobDetails.id} 
                    onChange={handleChange} 
                    placeholder="Job ID" 
                    required
                    min="0" // Only positive numbers
                    title="Only positive numbers are supported for Job ID"
                />
                <input 
                    type="text" 
                    name="location" 
                    value={jobDetails.location} 
                    onChange={handleChange} 
                    placeholder="Location" 
                    required 
                />
                <input 
                    type="text" 
                    name="skills" 
                    value={jobDetails.skills} 
                    onChange={handleChange} 
                    placeholder="Skills" 
                    required 
                />
                <textarea 
                    name="description" 
                    value={jobDetails.description} 
                    onChange={handleChange} 
                    placeholder="Job Description" 
                    required 
                ></textarea>
                <input 
                    type="number" 
                    name="experience" 
                    value={jobDetails.experience} 
                    onChange={handleChange} 
                    placeholder="Experience Required" 
                    required
                    min="0" // Only positive numbers
                    step="0.1" // Restrict to whole numbers
                    title="Only positive integer values are supported for Experience"
                />
                <input 
                    type="text" 
                    name="education" 
                    value={jobDetails.education} 
                    onChange={handleChange} 
                    placeholder="Education Required" 
                    required 
                />
                <button type="submit">Post Job</button>
            </form>

            {/* Display the message, whether success or error */}
            {message && <p>{message}</p>}
        </div>
    );
};

export default JobPostingForm;
