import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Services from '../Services/Services';
import Contact from '../Contact/Contact';
import AboutUs from '../AboutUs/AboutUs';
import './Home.css';
import part1Image from '../assets/images/part1.jpeg';
import part2Image from '../assets/images/part2.jpeg';
import part3Image from '../assets/images/part3.jpeg';

const Home = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [currentImage, setCurrentImage] = useState(part1Image);
  const [webinarStartDate, setWebinarStartDate] = useState('');
  const [webinarStartTime, setWebinarStartTime] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    const hasVisited = localStorage.getItem('hasVisitedHome');

    if (!hasVisited) {
      setShowPopup(true);
      localStorage.setItem('hasVisitedHome', 'true');
    }

    // Fetch the webinar date and time from the backend
    const fetchWebinarDetails = async () => {
      try {
        const response = await fetch('https://sloperatebackend-a8da1f1de4d3.herokuapp.com/api/webinars');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        // Assuming the API returns an array of webinar objects
        if (data && data.length > 0) {
          const latestWebinar = data[data.length - 1];
          setWebinarStartDate(latestWebinar.date);
          setWebinarStartTime(latestWebinar.time);
        }
      } catch (error) {
        console.error("Error fetching webinar details:", error);
      }
    };

    fetchWebinarDetails();

    const images = [part1Image, part2Image, part3Image];
    let imageIndex = 0;

    const interval = setInterval(() => {
      imageIndex = (imageIndex + 1) % images.length;
      setCurrentImage(images[imageIndex]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleViewJobs = () => {
    navigate('/jobs');
  };

  const handleConfirmMasterclass = () => {
    setShowPopup(false);
    navigate('/masterclass');
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="home-container">
      {/* Marquee for Webinar Message */}
      <marquee className="webinar-marquee">
        Webinar is going to start on {webinarStartDate} at {webinarStartTime}. Please register for the webinar as soon as possible in the Master Class.
      </marquee>

      {/* Popup */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Would you like to navigate to the Masterclass page?</h2>
            <div className="popup-buttons">
              <button onClick={handleConfirmMasterclass}>Yes, Take Me to Masterclass</button>
              <button onClick={handleClosePopup} className="no-button">Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Display the current image with overlay */}
      <div className="home-image-container">
        <img src={currentImage} alt="Current Display" className="home-image" />
      </div>

      <AboutUs />
      <Services />

      <div className="career-opportunities">
        <h2>CAREER OPPORTUNITIES!</h2>
        <h5>
          WE ARE LOOKING FOR EXPERTS. IF YOU ARE LOOKING TO EXPLORE ENORMOUS OPPORTUNITIES, GO FOR IT & APPLY
        </h5>
        <button onClick={handleViewJobs} className="view-jobs-button1">
          View Jobs
        </button>
      </div>

      <Contact />
    </div>
  );
};

export default Home;