import React, { useState, useEffect } from 'react';
import './MasterClass.css';
import benefit1 from '../assets/images/benefit1.png';
import benefit2 from '../assets/images/benefit2.png';
import benefit3 from '../assets/images/benefit3.png';
import RegistrationPopup from './RegistrationPopup';
import PopupForm from './PopupForm';
import Webinar from '../assets/images/Webinar.jpeg';
import axios from 'axios';

function MasterClass() {
  const benefitsData = [
    { title: 'Benefit 1', description: 'Master Class Practical Training.', image: benefit1 },
    { title: 'Benefit 2', description: 'Master Class Practical Training + Placement Assistance.', image: benefit2, isActive: true },
    { title: 'Benefit 3', description: 'Master advanced topics and get project assignments.', image: benefit3 },
  ];

  const [webinarStartDate, setWebinarStartDate] = useState();
  const [webinarStartTime, setWebinarStartTime] = useState();
  const [webinarSchedule1Date, setWebinarSchedule1Date] = useState();
  const [webinarSchedule1Time, setWebinarSchedule1Time] = useState();
  const [webinarSchedule2Date, setWebinarSchedule2Date] = useState();
  const [webinarSchedule2Time, setWebinarSchedule2Time] = useState();

  const fetchWebinarData = async () => {
    try {
      const response = await axios.get('https://sloperatebackend-a8da1f1de4d3.herokuapp.com/api/webinars');
      const webinarData = response.data;
      console.log('Latest Webinar Data:', webinarData[webinarData.length - 1]);

      if (webinarData && webinarData.length > 0) {
        const latestWebinar = webinarData[webinarData.length - 1];
        setWebinarStartDate(latestWebinar.date);
        setWebinarStartTime(latestWebinar.time);
        setWebinarSchedule1Date(latestWebinar.webinarschedule1startdate);
        setWebinarSchedule1Time(latestWebinar.webinarschedule1starttime);
        setWebinarSchedule2Date(latestWebinar.webinarschedule2startdate);
        setWebinarSchedule2Time(latestWebinar.webinarschedule2starttime);
      }
    } catch (error) {
      console.error('Error fetching webinar data:', error);
    }
  };



  useEffect(() => {
    fetchWebinarData();
  }, []);

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isRegistrationPopupVisible, setIsRegistrationPopupVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
  });
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [schedule1TimeLeft, setSchedule1TimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  const calculateTimeLeft = (targetDate, targetTime) => {
    if (!targetDate || !targetTime) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const targetTimeObj = new Date(targetDate);
    const [time, modifier] = targetTime.split(" ");

    if (!time || !modifier) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours < 12) hours += 12;
    if (modifier === "AM" && hours === 12) hours = 0;

    targetTimeObj.setHours(hours);
    targetTimeObj.setMinutes(minutes);

    const currentTime = new Date();
    const difference = targetTimeObj - currentTime;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
      return { days, hours, minutes, seconds };
    }

    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(webinarStartDate, webinarStartTime));
      setSchedule1TimeLeft(calculateTimeLeft(webinarSchedule1Date, webinarSchedule1Time));
    }, 1000);

    return () => clearInterval(timer);
  }, [webinarStartDate, webinarStartTime, webinarSchedule1Date, webinarSchedule1Time]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setIsTermsAccepted((prev) => !prev);
  };

  const openRegistrationPopup = () => {
    setIsRegistrationPopupVisible(true);
  };

  const closeRegistrationPopup = () => {
    setIsRegistrationPopupVisible(false);
  };

  const openPopup = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <div className="webinar-box">
      <div className="webinar-container">
        <div className="webinar-content">
          <div className="webinar-details">
            <div className="webinar-time">
              <p>Webinar Start Date: <strong>{webinarStartDate}</strong></p>
              <p>Webinar Start Time: <strong>{webinarStartTime}</strong></p>
            </div>
            <h2>Upcoming MasterClass</h2>
            <p>MasterClass designed to equip you with industry-best practices and hands-on training.</p>

            <div className="countdown-timer1">
              <h3>Webinar starts in:</h3>
              <div className="timer1">
                <div className="timer1-details">
                  <div className="timer1-box">
                    <span>{timeLeft.days}</span>
                    <label>Days</label>
                  </div>
                  <div className="timer1-box">
                    <span>{timeLeft.hours}</span>
                    <label>Hours</label>
                  </div>
                  <div className="timer1-box">
                    <span>{timeLeft.minutes}</span>
                    <label>Minutes</label>
                  </div>
                  <div className="timer1-box">
                    <span>{timeLeft.seconds}</span>
                    <label>Seconds</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="terms-checkbox">
              <input
                type="checkbox"
                id="termsCheckbox"
                checked={isTermsAccepted}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="termsCheckbox">
                Accept the <strong>Terms and Conditions</strong>
              </label>
            </div>

            {isTermsAccepted && (
              <div className="terms-conditions">
                <h3>Terms and Conditions</h3>
                <p>
                  Here are the terms and conditions for attending this MasterClass:
                  <br /> 1. You must agree to follow all the instructions.
                  <br /> 2. No sharing of the content or distributing the materials without permission.
                  <br /> 3. Fees are non-refundable unless otherwise stated.
                  <br /> 4. Attendees must attend the full session to receive a certificate.
                </p>
              </div>
            )}

            <div className="button-container">
              <button
                className="book-now1"
                onClick={openRegistrationPopup}
                disabled={!isTermsAccepted}
              >
                BOOK NOW AT ₹399
              </button>
            </div>
          </div>
          <div className="workshop-details">
            <div className="inner-workshop-box">
              <div className="logo-box">
                <div className="logo">
                  <img src={Webinar} alt="Logo" />
                </div>
              </div>
              <div className="register-now-section">
                <h3>Live 2 days workshop on Core Java</h3>
                <p>Learn industry "best practices" in Java software development from a professional Java developer with 25 years of experience.</p>
                <button className="register-now" onClick={openPopup}>
                  REGISTER NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="benefits-container">
        <h2>What Will You Learn?</h2>
        <div className="benefits-grid">
          {benefitsData.map((benefit, index) => (
            <div key={index} className={`benefit-item ${benefit.isActive ? 'active' : ''}`}>
              <img src={benefit.image} alt={benefit.title} className="benefit-image" />
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="schedule-box">
        <div className="webinar-schedule-container">
          <div className="webinar-schedule-left">
            <h2>Webinar Schedule</h2>
            <p>
              Explore the comprehensive schedule<br /> of our upcoming 75-day MasterClass<br /> webinar series, where you will gain <br />invaluable insights and skills that will<br /> elevate your expertise to new heights.
            </p>
            <button className="register2-button" onClick={openRegistrationPopup}>
              REGISTER NOW AT ₹29000
            </button>
            <div className="schedule-timer">
              <h3>Webinar starts in :</h3>
              <div className="countdown-timer2">
                <div className="timer2">
                  <div className="days-box">
                    <span>{schedule1TimeLeft.days}</span>
                    <label>Days</label>
                  </div>
                  <div className="timer2-box">
                    <span>{schedule1TimeLeft.hours}</span>
                    <label>Hours</label>
                  </div>
                  <div className="timer2-box">
                    <span>{schedule1TimeLeft.minutes}</span>
                    <label>Minutes</label>
                  </div>
                  <div className="timer2-box">
                    <span>{schedule1TimeLeft.seconds}</span>
                    <label>Seconds</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="webinar-schedule-right">
            <h3>Webinar Schedule Details</h3>
            <div className="schedule-details">
              <div className="schedule-item">
                <div className="schedule-date">
                  <p>{webinarSchedule1Date}</p>
                  <button className="time-button">{webinarSchedule1Time}</button>
                </div>
                <div className="schedule-description">
                  <h4>Session 1</h4>
                  <p>Introduction to Java Best Practices</p>
                </div>
              </div>

              <div className="schedule-item">
                <div className="schedule-date">
                  <p>{webinarSchedule2Date}</p>
                  <button className="time-button">{webinarSchedule2Time}</button>
                </div>
                <div className="schedule-description">
                  <h4>Session 2</h4>
                  <p>Advanced Java Concepts and Hands-on Projects</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="speakers-section">
        <div className="speakers-container">
          <h2 className="speakers-heading">Webinar Speakers</h2>
          <div className="speakers-row">
            <div className="speaker-card">
              <div className="speaker-image">
                <img src="https://via.placeholder.com/100" alt="Speaker 1" />
              </div>
              <div className="speaker-info">
                <h3>Thiru</h3>
                <p>Senior Java Developer</p>
                <p>25 years of experience in software development.</p>
                <p>Thiru@sloperateinc.com</p>
              </div>
            </div>
            <div className="speaker-card">
              <div className="speaker-image">
                <img src="https://via.placeholder.com/100" alt="Speaker 2" />
              </div>
              <div className="speaker-info">
                <h3>Mani</h3>
                <p>Lead Software Engineer</p>
                <p>Expert in Java frameworks and best practices.</p>
                <p>Smanikanta@sloperateinc.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isRegistrationPopupVisible && (
        <RegistrationPopup
          formData={formData}
          handleInputChange={handleInputChange}
          closePopup={closeRegistrationPopup}
        />
      )}

      {isPopupVisible && (
        <PopupForm
          closePopup={closePopup}
          onInputChange={handleInputChange}
          formData={formData}
        />
      )}
    </div>
  );
}

export default MasterClass;
