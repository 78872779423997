import React from 'react';
import './Services.css';
import { FaGlobe, FaMobileAlt, FaUserCog, FaBrain, FaRobot } from 'react-icons/fa'; // Import icons
import AI1 from '../assets/images/AI1.jpg'; 

const Services = () => {
    return (
        <div className="services-container">
            <h1 className="services-heading">SERVICES</h1>
            <h3 className="services-description">
                AS A TRUSTED PARTNER, WE OFFER TOP-NOTCH SERVICES ACROSS FINTECH, HEALTHCARE, PRODUCT, AND SERVICE-BASED INDUSTRIES. OUR FOCUS ON QUALITY AND INNOVATION HELPS BUSINESSES ACHIEVE DIGITAL TRANSFORMATION AND SUSTAINABLE GROWTH.
            </h3>

            <div className="services-grid">
                {/* Web and Mobile Development */}
                <div className="service-box">
                    <FaGlobe className="service-icon" />
                    <h2>WEB DEVELOPMENT</h2>
                    <h5>
                        • CUSTOM WEB AND MOBILE APPLICATIONS TAILORED TO MEET BUSINESS NEEDS.<br />
                        • SCALABLE, RESPONSIVE, AND FEATURE-RICH SOLUTIONS ACROSS INDUSTRIES.
                    </h5>
                </div>

                {/* Artificial Intelligence with Image */}
                <div className="service-box">
                    <img src={AI1} alt="Artificial Intelligence Icon" className="ai-image" /> {/* AI Image above the section */}
                    <h2>ARTIFICIAL INTELLIGENCE</h2>
                    <h5>
                        • AI-POWERED SOLUTIONS FOR AUTOMATION AND USER INTERACTION.<br />
                        • DATA ANALYSIS AND MACHINE LEARNING FOR BUSINESS INSIGHTS.
                    </h5>
                </div>

                {/* Robotics Section with FaRobot Icon */}
                <div className="service-box">
                    <FaRobot className="service-icon" />
                    <h2>ROBOTICS</h2>
                    <h5>
                        • ROBOTIC PROCESS AUTOMATION (RPA) TO STREAMLINE WORKFLOWS AND ENHANCE PRODUCTIVITY.<br />
                        • SOLUTIONS FOR AUTOMATION IN MANUFACTURING, LOGISTICS, AND BEYOND.
                    </h5>
                </div>

                {/* Mobile Development */}
                <div className="service-box">
                    <FaMobileAlt className="service-icon" />
                    <h2>MOBILE DEVELOPMENT</h2>
                    <h5>
                        • END-TO-END SOLUTIONS FOR FINANCIAL TECHNOLOGY PLATFORMS.<br />
                        • DEVELOPMENT AND SUPPORT FOR SAAS-BASED AND OTHER DIGITAL PRODUCTS.
                    </h5>
                </div>

                {/* Healthcare & Service-based Solutions */}
                <div className="service-box">
                    <FaUserCog className="service-icon" />
                    <h2>HEALTHCARE & SERVICE-BASED SOLUTIONS</h2>
                    <h5>
                        • COMPLIANT, SCALABLE HEALTHCARE SOFTWARE ALIGNED WITH GLOBAL STANDARDS.<br />
                        • SERVICE-BASED SOLUTIONS DESIGNED TO OPTIMIZE OPERATIONAL EFFICIENCY.
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default Services;
