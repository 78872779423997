import React from 'react';
import emailjs from 'emailjs-com';
import scannerImage from '../assets/images/scanner.jpg';
import './RegistrationPopup.css';

const RegistrationPopup = ({ formData, handleInputChange, closePopup }) => {
  
  const sendEmailWithTerms = (e) => {
    e.preventDefault();

    // Validate form data
    if (formData.firstName && formData.lastName && formData.email && formData.mobileNumber && formData.utrNumber) {
      const templateParams = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        mobileNumber: formData.mobileNumber,
        utrNumber: formData.utrNumber,
        bccEmail : 'admin@sloperateinc.com'
       
      };

      emailjs.send(
        'service_4m4hlqa',
        'template_9zatcg9',
        templateParams,
        'GqSl46xj1YpuCDxNR'
      )
      .then((result) => {
        alert('Registration successful! The terms and conditions PDF has been sent to your email.');
        closePopup();
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
        alert('Error in sending the email, please try again.');
      });
    } else {
      alert('Please complete all the form fields.');
    }
  };

  return (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <h2>Registration Details</h2>
        <form onSubmit={sendEmailWithTerms}>
          <div>
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Mobile Number:</label>
            <input
              type="tel"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="scanner-image">
            <img src={scannerImage} alt="Scanner" />
            <p>Scan your UTR number and enter it below.</p>
          </div>
          <div>
            <label>UTR Number:</label>
            <input
              type="text"
              name="utrNumber"
              value={formData.utrNumber}
              onChange={handleInputChange}
              required
            />
          </div>
          
          <div className="button-container">
            <button type="submit">Submit</button>
            <button type="button" onClick={closePopup}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationPopup;
