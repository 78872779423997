import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useLocation, useNavigate } from 'react-router-dom';
import './JobApplicationForm.css';

const JobApplicationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract jobId from location state
  const jobId = location.state?.jobId || '';
  const jobTitle = location.state?.jobTitle || ''; // Extract jobTitle from location state

  const [formData, setFormData] = useState({
    jobTitle: jobTitle, // Add jobTitle to state
    name: '',
    email: '',
    mobileNumber: '',
    qualification: '',
    skills: '',
    gender: '',
    experience: '',
    resume: null,
    comments: '',
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'resume') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(formData);
    alert('Application submitted successfully!');
    setFormData({
      jobTitle: jobTitle, // Reset jobTitle on form submit
      name: '',
      email: '',
      mobileNumber: '',
      qualification: '',
      skills: '',
      gender: '',
      experience: '',
      resume: null,
      comments: '',
    });
    navigate('/careers'); // Redirect to careers page
  };

  const sendEmail = (formData) => {
    emailjs.init('xZF8IZzuBUra4Cqhm'); // Initialize with your public key
    const templateParams = {
      job_id: jobId,
      job_title: formData.jobTitle, // Add jobTitle to templateParams
      from_name: formData.name,
      from_email: formData.email,
      mobile_number: formData.mobileNumber,
      qualification: formData.qualification,
      skills: formData.skills,
      gender: formData.gender,
      experience: formData.experience,
      resume: formData.resume ? formData.resume.name : '',
      comments: formData.comments,
    };
    emailjs
      .send('service_bfwjb7i', 'template_u01kt4i', templateParams)
      .then((response) => {
        console.log('Email sent successfully', response);
      })
      .catch((error) => {
        console.error('Error sending email', error);
      });
  };

  return (
    <div className="job-application-form">
      <h2>Job Application Form</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group">
          <label htmlFor="jobTitle">Job Title</label>
          <input
            type="text"
            id="jobTitle"
            name="jobTitle"
            value={formData.jobTitle}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="jobId">Job ID</label>
          <input
            type="text"
            id="jobId"
            name="jobId"
            value={jobId}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobileNumber">Mobile Number</label>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="qualification">Qualification</label>
          <select
            id="qualification"
            name="qualification"
            value={formData.qualification}
            onChange={handleChange}
            required
          >
            <option value="">Select Qualification</option>
            <option value="High School">High School Diploma/GED</option>
            <option value="Associate">Associate's Degree</option>
            <option value="Bachelor">Bachelor's Degree</option>
            <option value="Master">Master's Degree</option>
            <option value="PhD">Doctorate/Ph.D.</option>
            <option value="Vocational">Vocational/Technical Training</option>
            <option value="Certification">Professional Certification</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="skills">Skills</label>
          <input
            type="text"
            id="skills"
            name="skills"
            value={formData.skills}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="gender">Gender</label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="experience">Experience</label>
          <input
            type="text"
            id="experience"
            name="experience"
            value={formData.experience}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="resume">Resume</label>
          <input
            type="file"
            id="resume"
            name="resume"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="comments">Comments</label>
          <textarea
            id="comments"
            name="comments"
            rows="3"
            value={formData.comments}
            onChange={handleChange}
          ></textarea>
        </div>
        <button type="submit" className="submit-btn">Submit Application</button>
      </form>
    </div>
  );
};

export default JobApplicationForm;